.render_icon_container {
  height: 25px;
  width: 25px;
}

/* .render_icon_content {
  width: 20px;
  height: 20px;
  position: relative;
  background-color: transparent;
} */

.render_icon_content img {
  display: block;
  height: auto;
  object-fit: cover;
  width: 100%;
}

/* Book Cover Effect */
.render_icon_content::before {
  content: "";
  position: absolute;
  inset: 0px;
  pointer-events: none;
  filter: contrast(310%) brightness(100%);
}
